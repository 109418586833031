<template>
  <div class="body">
    <recharge-method-view :recharge-method-object="rechargeMethodObject" @handleChange="handleChange"/>
    <div class="margin-top40 line-height42 font-size30 font-weightBold font-color1B1B1B margin-left30">
      兑换算力
    </div>
    <div class="flex_able"
         style="background:#FAFAFA;margin-top: var(--30);border-radius: var(--24);height: var(--112);
        margin-left: var(--30);margin-right: var(--30);padding-left: var(--30);padding-right: var(--30);">
      <van-field style="flex:1;margin-right: var(--30);" v-model="addressValue" type="number" :placeholder=minAmountStr
                 clearable/>
      <span class="font-size28 font-color1B1B1B line-height40">算力</span>
    </div>

    <div v-if="errorMsg.length > 0" style="color:#F35757;margin-top: var(--18);"
         class="font-size26 line-height36 margin-left30">
      {{ errorMsg }}
    </div>

    <div class="margin-left30 margin-top40">
      <span class="font-size28 line-height40 font-color7E90A0">需支付：</span>
      <span class="font-color026AFC font-size30 font-weightBold">{{ needPayAmount }}</span>
      <span class="font-color1B1B1B font-size30 font-weightBold margin-left8">X币</span>
    </div>

    <div class="margin-left30 margin-top40 flex_able" style="align-items: center">
      <span class="font-size28 line-height40 font-color7E90A0">可用余额：</span>
      <span class="font-color026AFC font-size30 font-weightBold">{{ balanceX }}</span>
      <span class="font-color1B1B1B font-size30 font-weightBold margin-left8 margin-right36">X币</span>
      <title-arrow
          @click.native="goToTopUpPower"
          textColor="#FEAE4F"
          lineHeight="var(--40)"
          textSize="var(--28)"
          imageWidth="var(--14)"
          imageHeight="var(--24)"
          marginLeft="var(--6)"
          imageUrl="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/e328fbdb-69ac-43c3-a043-030ce39614f1.png"
          text="去充值"/>
    </div>
    <div class="flex_center" style="margin-top: var(--146)">
      <div @click="goToGetPowerDetail" class="flex_center font-size30 line-height42 "
           style="width: var(--320);height: var(--88);
          border-radius: var(--44);font-size: var(--30);color: #268DFF;
          border: var(--2) solid #268DFF;">
        查看算力明细
      </div>
      <div @click="submitInfo" class="flex_center margin-left30"
           :style="{'background': (isEmpty(errorMsg) && !isEmpty(addressValue)) ? '#268DFF' : '#BEDDFF'}"
           style="width: var(--320);height: var(--88);
          border-radius: var(--44);font-size: var(--30);font-weight: 400;color: #FFFFFF;">
        确认兑换
      </div>
    </div>
  </div>
</template>

<script>
//开始1
import {Dialog, Toast} from 'vant';
import {Api} from '@/utils/net/Api';
import {Constants} from "../../../utils/constants";
import TitleArrow from "../../../components/TitleArrow.vue";
import EncryptUtils from "../../../utils/EncryptUtils";
import RechargeMethodView from "../../../components/rechargeMethodView.vue";
//开始2
export default {
  components: {RechargeMethodView, TitleArrow},
  data() {
    //开始4
    //初始化数据
    return {
      errorMsg: "",
      needPayAmount: 0,
      balanceX: 0,
      rate: 0,
      minAmount100: 0,
      minAmount270: 0,
      minAmount365: 0,
      maxAmount: 0,
      minAmountStr: '',
      addressValue: '',
      rechargeMethodObject: {
        selectIndexType: 0
      },
    }
  },
  watch: {
    addressValue(val, newVal) {
      console.log("val" + val)
      console.log("newVal" + newVal)
      this.checkAddress(val)
    }
  },
  created() {
    //查询对方接收藏品id的用户
    this.id = this.$route.query.id
    //开始5
    try {
      //设置标题
      document.title = "兑换算力"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.queryConfigEntityPure()
  },
  methods: {
    checkAddress(val){
      if (val > this.balanceX) {
        this.errorMsg = "可用余额不足"
      } else if(this.rechargeMethodObject.selectIndexType == 0 && val < this.minAmount100){
        this.errorMsg = "最小起兑数量为" + this.minAmount100 + "算力"
      } else if (this.rechargeMethodObject.selectIndexType == 1 && val < this.minAmount270) {
        this.errorMsg = "最小起兑数量为" + this.minAmount365 + "算力"
      } else if (this.rechargeMethodObject.selectIndexType == 2 && val < this.minAmount365) {
        this.errorMsg = "最小起兑数量为" + this.minAmount365 + "算力"
      } else {
        this.errorMsg = ""
      }
      if(EncryptUtils.isEmpty(val)){
        this.errorMsg = ""
      }
      console.log(this.rate)
      console.log(this.balanceX)
      this.needPayAmount = this.addressValue * this.rate
      console.log(this.needPayAmount)
    },
    handleChange(index) {
      //当来回切换的时候重置一下输入的数值
      this.addressValue = ""
      //重新校验
      this.checkAddress("")
      console.log("封神妲己发搜房" + index)
      if (index == 0) {
        this.minAmountStr = this.minAmount100 + "算力起兑" //100天的最小额度
      } else if (index == 1) {
        this.minAmountStr = this.minAmount270 + "算力起兑"  //365天最小额度
      } else if (index == 2) {
        this.minAmountStr = this.minAmount365 + "算力起兑"  //365天最小额度
      }
    },
    goToGetPowerDetail() {
      this.$router.push({
        path: '/getPowerDetail',
      });
    },
    goToTopUpPower() {
      this.$router.push({
        path: '/topUpPower',
      });
    },
    isEmpty(obj) {
      return typeof obj == "undefined" || obj == null || obj == "";
    },
    //提取大链上
    submitInfo() {
      //判断地址
      if (!this.isEmpty(this.errorMsg)) {
        return
      }
      if (this.isEmpty(this.addressValue)) {
        return
      }
      Dialog.confirm({
        title: "确认兑换",
        message: '本次获取' + this.addressValue + '算力，需要支付' + this.needPayAmount + 'X币，是否确认兑换？',
        confirmButtonText: "确定",
        confirmButtonColor:"#ffffff",
        cancelButtonColor:"#666666",
        messageAlign:"left",
        getContainer:".body"
      }).then(() => {
        this.exchangePower()
      }).catch(() => {
        console.log('cancel');
      })
    },
    exchangePower() {
      let expiryDateTemp = 100
      if (this.rechargeMethodObject.selectIndexType == 0) {
        expiryDateTemp = 100
      } else if (this.rechargeMethodObject.selectIndexType == 1) {
        expiryDateTemp = 270
      } else if (this.rechargeMethodObject.selectIndexType == 2) {
        expiryDateTemp = 365
      }
      let params = {
        amount: this.addressValue,
        expiryDate: expiryDateTemp//失效天数
      }
      Api.exchangePower(params).then((result) => {
        if (result.code === 100) {
          Toast("获取成功");
          this.addressValue = ""
          this.queryConfigEntityPure()
        } else {
          Toast(result.msg);
        }
      })
    },
    queryPowerAccount() {
      let params = {
        assetType: Constants.X_COIN,
      }
      let that = this;
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          that.balanceX = result.data?.accountVo?.balance
        } else {
          Toast(result.msg);
        }
      })
    },
    queryConfigEntityPure() {
      let data = {
        fromAssetType: Constants.X_COIN,
        toAssetType: Constants.POWER_TYPE,
      }
      Api.queryConfigEntityPure(data).then(res => {
        if (res.code === 100) {
          console.log(res)
          EncryptUtils.formatDecimal(0.10)
          this.minAmount270 = res.data.entity.minAmount3
          this.minAmount100 = res.data.entity.minAmount2
          this.minAmount365 = res.data.entity.minAmount
          this.maxAmount = res.data.entity.maxAmount
          this.rate = res.data.entity.rate
          this.handleChange(this.rechargeMethodObject.selectIndexType)
          //请求余额
          this.queryPowerAccount()
        } else {
          Toast(res.msg);
        }
      })
    },
    // 右上角点击事件回调
    clickRightTitle() {
      //后期可以用标题作为唯一判断
      //开启提币记录
      window.location.href = Constants.goToExtractRecordsList
    },
  },
}
</script>

<style scoped>
.body {
  background: #ffffff;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/*弹窗的样式修改开始*/
/deep/ .van-dialog {
  border-radius: var(--10);
}

/deep/ .van-dialog__header {
  color: #1B1B1B;
  font-size: var(--36);
  font-weight: bold;
}

/deep/ .van-dialog__message {
  color: #333333;
  font-size: var(--30);
}

/deep/ .van-dialog__confirm{
  margin-left: var(--34);
  margin-right: var(--22);
  margin-bottom: var(--30);
  background: #268DFF;
  border-radius: var(--44);
  width:  var(--272);
  height: var(--88);
}

/deep/ .van-dialog__cancel{
  margin-left: var(--34);
  background: #EEEEEE;
  border-radius: var(--44);
  width:  var(--272);
  height: var(--88);
}
/*弹窗更改样式结束*/

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #7E90A0;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--112);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1B1B1B;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  left: 0;
  border: none;
}
</style>
