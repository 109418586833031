import { render, staticRenderFns } from "./getPower.vue?vue&type=template&id=4eb1d9cf&scoped=true"
import script from "./getPower.vue?vue&type=script&lang=js"
export * from "./getPower.vue?vue&type=script&lang=js"
import style0 from "./getPower.vue?vue&type=style&index=0&id=4eb1d9cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb1d9cf",
  null
  
)

export default component.exports